import { render, staticRenderFns } from "./CreateUser.vue?vue&type=template&id=61c649ff&scoped=true&"
import script from "./CreateUser.vue?vue&type=script&lang=js&"
export * from "./CreateUser.vue?vue&type=script&lang=js&"
import style0 from "./CreateUser.vue?vue&type=style&index=0&id=61c649ff&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c649ff",
  null
  
)

export default component.exports